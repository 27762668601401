import { createTheme } from "@mui/material/styles";
import { SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
import { getQueryArgs } from "../utils/query-params";
import { JM, RAMDOR } from "../utils/clients";

const primaryByClient = (): SimplePaletteColorOptions => {
  return {
    light: "#626a7e",
    main: "#4B5263",
    dark: "#3d4456",
  };
};

const getPrimaryPalette = (): SimplePaletteColorOptions => {
  const { light, main, dark } = primaryByClient();
  const contrastText = "#fff";

  return {
    light,
    main,
    dark,
    contrastText,
  };
};

const theme = createTheme({
  palette: {
    primary: getPrimaryPalette(),
    secondary: {
      light: "#fac455",
      main: "#ffb400",
      dark: "#e3a000",
      contrastText: "#fff",
    },
    error: {
      light: "#ffd2d2",
      main: "#f44336",
      dark: "#d8000c",
      contrastText: "#fff",
    },
    info: {
      main: "#0078ff",
      light: "rgba(255,255,255,0.9)",
    },
  },
  typography: {
    fontFamily: "Mukta, sans-serif",
    fontSize: 16,
  },
});

export default theme;
