import * as React from "react";
import { useContext } from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/styles";

import { analyticsEvent } from "../../utils/analytics";
import { LoggedUserContext } from "../../context/LoggedUserContext";
import { getQueryArgs } from "../../utils/query-params";
import { GREENBOOK } from "../../utils/clients";
import { webViewMode } from "../../utils/webview-messenger";
import { WEBSITE } from "../../utils/site-routes";

interface LogoImage {
  href: string;
  src: string;
  mobileSrc: string;
}

const clientArg = getQueryArgs("client");

const getLogoImageByClient = (): LogoImage => {
  return {
    href: WEBSITE,
    src: "/logos/exponet.png",
    mobileSrc: "/logos/android-chrome-192x192.png",
  };
};

const topOffsetByClient = () => (clientArg === GREENBOOK ? "1.5vh" : "9vh");

export interface LogoButtonProps {
  mobileMode?: boolean;
  transparent?: boolean;
}

const LogoLink = styled("a")({
  marginLeft: "auto",
});

const LogoImg = styled("img")({
  height: "2.5rem",
});

const LogoTransparentImage = styled("img")({
  height: "1.1rem",
  position: "fixed",
  top: topOffsetByClient(),
  right: "3vh",
  zIndex: 1000,
});

export const LogoButton: React.FC<LogoButtonProps> = ({
  mobileMode = false,
  transparent = false,
}) => {
  const { loggedUser } = useContext(LoggedUserContext);
  const { href, src, mobileSrc } = getLogoImageByClient();
  const inWebView = webViewMode();
  const linkTarget = !inWebView ? "_blank" : "_self";

  return (
    <LogoLink
      target={linkTarget}
      href={href}
      rel="noopener noreferrer"
      onClick={() => {
        analyticsEvent("Navigation", "Logo Icon Clicked", loggedUser.username);
      }}
    >
      {transparent ? (
        <LogoTransparentImage
          alt="website"
          src="/logos/castory-transparent.svg"
        />
      ) : (
        <IconButton
          href={!inWebView ? href : "javascript:void(0);"}
          target={linkTarget}
          onClick={() => {
            analyticsEvent(
              "Navigation",
              "Logo Icon Clicked",
              loggedUser.username
            );
          }}
          size="large"
        >
          {mobileMode ? (
            <img alt="Exponet" src={mobileSrc} height="39px" />
          ) : (
            <img alt="Exponet" src={src} height="39px" />
          )}
        </IconButton>
      )}
    </LogoLink>
  );
};
