const RAMDOR = "ramdor";
const JM = "jm";
const GREENBOOK = "greenbook";
const EXPONET = "exponet";
const NA = "NA";

interface SupportDetails {
  mail: string;
  phone: string;
}

const isExponetUrl = () => window.location.href.includes(EXPONET);

const supportInfo = (): SupportDetails => {
  return {
    mail: "support@expo-net.co.il",
    phone: "+972-3-6244467",
  };
};

const isReseller = (client?: string) => client === EXPONET || client === RAMDOR;

export {
  RAMDOR,
  GREENBOOK,
  EXPONET,
  JM,
  NA,
  supportInfo,
  isExponetUrl,
  isReseller,
};
